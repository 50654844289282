.option-item {
  display: flex;
  justify-content: space-between;
}

.group-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
