.select_popup,
.button_redirect {
  justify-content: center;
  align-items: center;
  width: 550px;
  border-radius: 12px;
  box-shadow: 0px 4px rgba(194, 206, 214, 0.2);
}

.select_popup {
  padding: 8px 3px 8px 16px;
}

.button_redirect {
  padding: 8px 16px 8px 16px;
}
